export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/imposta-veicolo',
    name: 'set-vehicle',
    component: () => import('@/views/drivers/vehicle/SetVehicle.vue'),
    meta: {
      resource: 'WithoutVehicle',
      redirectIfVehicleActive: false,
    },
  },
  {
    path: '/carichi',
    name: 'cargo-list',
    component: () => import('@/views/drivers/cargo/CargoList.vue'),
    meta: {
      pageTitle: 'I tuoi carichi',
      redirectIfVehicleActive: false,
    },
  },
  {
    path: '/carico/:cargo',
    name: 'cargo',
    component: () => import('@/views/drivers/cargo/Cargo.vue'),
    meta: {
      pageTitle: '',
      navActiveLink: 'cargo-list',
    },
  },
  {
    path: '/carico/:cargo/:delivery',
    name: 'delivery',
    component: () => import('@/views/drivers/delivery/Delivery.vue'),
    meta: {
      pageTitle: '',
      navActiveLink: 'cargo-list',
    },
  },
  {
    path: '/configurazione-carichi',
    name: 'configurazione-carichi',
    component: () => import('@/views/drivers/cargo-edit/cargosList.vue'),
    meta: {
      pageTitle: 'Configurazione carichi',
      resource: 'WithoutVehicle',
      breadcrumb: [
        {
          text: 'Conf. Carichi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/configurazione-carichi/edit/:cargo',
    name: 'configurazione-carichi-edit',
    component: () => import('@/views/drivers/cargo-edit/cargo.vue'),
    meta: {
      pageTitle: '',
      resource: 'WithoutVehicle',
      navActiveLink: 'configurazione-carichi',
    },
  },
  {
    path: '/software',
    name: 'software',
    component: () => import('@/views/Software.vue'),
    meta: {
      pageTitle: 'Software',
      resource: 'WithoutVehicle',
      breadcrumb: [
        {
          text: 'Download software',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ddt-cleaning',
    name: 'ddt-cleaning',
    component: () => import('@/views/DDTCleaning.vue'),
    meta: {
      pageTitle: 'Pulizia DDT',
      resource: 'WithoutVehicle',
      breadcrumb: [
        {
          text: 'Pulizia DDT',
          active: true,
        },
      ],
    },
  },
]
