import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueCookies from 'vue-cookies'

import { localize } from 'vee-validate'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'

import 'leaflet/dist/leaflet.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueCookies)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('Registration successful, scope is:', registration.scope)
    })
    .catch(error => {
      console.log('Service worker registration failed, error:', error)
    })
}

new Vue({
  router,
  store,
  i18n,
  data() {
    return {
      user_logged: false,
      user_data: null,
      user_active_datetime: false,
      vehicle_active: false,
    }
  },
  computed: {
    vehicleID() {
      return this.$store.state.vehicle.vehicle.code
    },
    vehicleData() {
      return this.$store.state.vehicle.vehicle.data
    },
  },
  mounted() {
    localize(i18n.locale) // set vee-validate locale
  },
  render: h => h(App),
}).$mount('#app')
