import Vue from 'vue'

// axios
import axios from 'axios'
import ToastificationContent from '@core_custom/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '../router'
import appConfig from '../../config.json'
// import { logout } from '@mixins/auth'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: appConfig[store.state.scope.appScope].api_base_url,

  // timeout: 1000,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
  },
})

// axiosIns.defaults.withCredentials = true // https://laravel.com/docs/9.x/sanctum#cors-and-cookies

axiosIns.interceptors.request.use(
  config => {
    const token = localStorage.getItem('userToken')
    const vehicle_code = localStorage.getItem('vehicleCode')

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }

    if (vehicle_code) {
      // eslint-disable-next-line no-param-reassign
      config.headers.VehicleCode = `${vehicle_code}`
    }

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('userData')
      localStorage.removeItem('userToken')
      store.commit('vehicle/REMOVE_VEHICLE')

      if (router.history.current.name !== 'auth-login') {
        router.push({ name: 'auth-login' })
      }
      // logout.methods.logout()
    }

    if (router.history.current.name !== 'auth-login') {
      let ToastTitle = `Errore ${error.response.status}`

      if (error.response.status === 422) {
        ToastTitle = 'Errore di validazione'
      }

      let ToastText = ''
      if (typeof error === 'string') {
        ToastText = error
      }

      console.log(error.response.data.errors)

      if (error.response.data.message && typeof error.response.data.message === 'string') {
        ToastText = error.response.data.message
      }
      if (error.response.data.errors && typeof error.response.data.errors === 'object') {
        ToastText = ''

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, DataError] of Object.entries(error.response.data.errors)) {
          ToastText = `${ToastText + DataError[0]}<br />`
        }
      }

      Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: ToastTitle,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: ToastText,
          // text: `${error.response.data.message || error}`,
        },
      })
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
