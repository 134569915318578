/*** GESTIONE SCOPE MULTIDOMINIO  ***/

const current_domain = location.host
const arredo3_domain = process.env.VUE_APP_ARREDO3_DOMAIN
const prima_domain = process.env.VUE_APP_PRIMA_DOMAIN

let app_scope

switch (current_domain) {
  case arredo3_domain: {
    app_scope = 'arredo3'
    break;
  }
  case prima_domain: {
    app_scope = 'prima'
    break;
  }
  default: {
    app_scope = 'arredo3'
  }
}
console.log('======== SCOPE =======')
console.log(app_scope)

/*** END | GESTIONE SCOPE MULTIDOMINIO  ***/

export default {
  namespaced: true,
  state: {
    appScope: app_scope,
    cargoInProgress: {
      state: 'open',
    },
  },
  getters: {},
  mutations: {
    SET_APP_SCOPE_STATE(state, val) {
      state.appScope = val
    },
    SET_CARGO_IN_PROGRESS_STATE(state, val) {

      let valid_states = ['open', 'closed']

      if(valid_states.includes(val)){
        state.cargoInProgress.state = val
      }
      else{
        state.cargoInProgress.state = 'open'
      }

    }
  },
  actions: {},
}
