import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {
  isUserLoggedIn, getUserData, getHomeRouteForVehicleActive, getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import pages from './routes/pages'

// External Libs
import axiosIns from '../libs/axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    /* {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    }, */
    { path: '/', redirect: { name: 'cargo-list' } },
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  let isAuthenticated
  const userToken = localStorage.getItem('userToken')
  const vehicleCode = localStorage.getItem('vehicleCode')
  // console.log('TOKEN')
  // console.log(userToken)
  // console.log('USER LOGGED:')
  // console.log(router.app.user_logged)
  // console.log('USER ACTIVITY DATA:')
  // console.log(router.app.user_active_datetime)

  const { user_logged, vehicle_active, user_data } = router.app
  const current_datetime = new Date().getTime()
  const { user_active_datetime } = router.app
  let user_active_datetime_expiration = false
  if (user_active_datetime) {
    // eslint-disable-next-line camelcase
    user_active_datetime_expiration = user_active_datetime.setHours(user_active_datetime.getHours() + 1)
  }

  // console.log('USER ACTIVITY DATA EXPIRATION:')
  // console.log(user_active_datetime_expiration)
  // console.log(current_datetime)

  // Verifico l'esistenza del token nel local storage
  // Se non esiste l'utente non è loggato
  if (!userToken) {
    if (to.meta.resource === 'Auth') {
      next()
    } else {
      next({ name: 'auth-login' })
    }
    // eslint-disable-next-line camelcase
  } else if (userToken && router.app.user_logged && user_active_datetime && user_active_datetime_expiration && (user_active_datetime_expiration > current_datetime)) {
    // Bypasso il controllo via API se l'utente è attivo da un'ora e il token non è scaduto

    // Se non è impostato il veicolo visito solo le rotte abilitate altrimenti vado alla pagina di set-vehicle
    if (!vehicleCode) {
      if (to.meta.resource === 'WithoutVehicle') {
        next()
      } else {
        next({ name: 'set-vehicle' })
      }
    }

    if (to.meta.redirectIfLoggedIn) {
      next(getHomeRouteForLoggedInUser())
    } else {
      next()
    }
  } else {
    // Verifico se il token è valido attraverso le api

    axiosIns.post('/checklogged').then(response => {
      // console.log('CHECKLOGGED - API')
      isAuthenticated = response.data.message === 'Logged'

      if (isAuthenticated) {
        // Se valido salvo le variabili locali
        router.app.user_logged = true
        router.app.user_active_datetime = new Date()
        router.app.user_data = true

        // Se non è impostato il veicolo visito solo le rotte abilitate altrimenti vado alla pagina di set-vehicle
        if (!vehicleCode) {
          if (to.meta.resource === 'WithoutVehicle') {
            next()
          } else {
            next({ name: 'set-vehicle' })
          }
        }

        if (to.meta.redirectIfLoggedIn) {
          next(getHomeRouteForLoggedInUser())
        } else {
          next()
        }
      } else {
        next({ name: 'auth-login' })
      }
    }).catch(error => {
      if (to.meta.resource === 'Auth') {
        next()
      } else {
        next({ name: 'auth-login' })
      }
    })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from, failure) => {
  // Remove initial loading
  if (router.app.$root.$data.user_logged) {
    router.app.user_active_datetime = new Date()
  }

  // console.log('AFTER')
  // console.log(router.app.user_logged)
  // console.log(router.app.user_active_datetime)

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
