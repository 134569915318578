export default {
  namespaced: true,
  state: {
    vehicle: {
      code: localStorage.getItem('vehicleCode') || null,
      data: JSON.parse(localStorage.getItem('vehicleData')) || null,
    },
  },
  getters: {},
  mutations: {
    SET_VEHICLE(state, vehicleData) {
      state.vehicle.code = vehicleData.c_automezzo
      state.vehicle.data = vehicleData

      // Update value in localStorage
      localStorage.setItem('vehicleCode', vehicleData.c_automezzo)
      localStorage.setItem('vehicleData', JSON.stringify(vehicleData))
    },
    REMOVE_VEHICLE(state) {
      state.vehicle.code = null
      state.vehicle.data = null

      // Remove value in localStorage
      localStorage.removeItem('vehicleCode')
      localStorage.removeItem('vehicleData')
    },
  },
  actions: {},
}
