export default {
  namespaced: true,
  state: {
    cargo: {
      showClosedDeliveries: false,
    },
  },
  getters: {},
  mutations: {
    SET_SHOW_CLOSED_DELIVERIES(state, showClosedDeliveries) {
      state.cargo.showClosedDeliveries = showClosedDeliveries
    },
  },
  actions: {},
}
